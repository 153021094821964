<template>
  <form @submit.prevent="sendLead">
    <div v-if="formStatus === 'pending'" class="contact-form bare">
      <h2>Request a demo</h2>
      <p>We'd love to chat about your delivery requirements and give you a tour of Filmflow.</p>
      <div class="form-row">
        <div class="form-item">
          <label for="item-first-name">First Name</label>
          <input id="item-first-name" v-model="firstName" type="text">
        </div>
      </div>
      <div class="form-row">
        <div class="form-item">
          <label for="item-first-name">Last Name</label>
          <input id="item-last-name" v-model="lastName" type="text">
        </div>
      </div>
      <div class="form-row">
        <div class="form-item">
          <label for="item-email">Your Role</label>
          <select id="role" v-model="role">
            <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-item">
          <label for="item-email">Email Address</label>
          <input id="item-email" v-model="email" type="text">
        </div>
      </div>
      <div class="form-row mailing-list">
        <!-- <div class="form-item checkbox">
          <input id="item-list" v-model="mailingList" type="checkbox">
          <label for="item-list">Sign me up for the Filmflow email list (1-2 per month)</label>
        </div>-->
      </div>
      <div v-if="invalidForm" class="invalid-form">Please fill out all fields.</div>
      <div v-if="serverError" class="invalid-form">
        <template
          v-if="serverError.response"
        >Invalid data in: {{ serverError.response.data.error.join(', ') }}</template>
        <template
          v-else
        >There was a problem connecting to the server. Please send an e-mail to info@nicedissolve.com instead.</template>
      </div>
      <button class="green full-width" type="submit">Send</button>
    </div>
    <div v-else-if="formStatus === 'sending'" class="contact-form bare center">Sending...</div>
    <div
      v-else-if="formStatus === 'sent'"
      class="contact-form bare center"
    >Thanks! We'll be in touch.</div>
  </form>
</template>

<script>
import axios from 'axios'

export default {
  components: {},
  props: { defaultRole: { type: String, default: '' } },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      mailingList: true,
      invalidForm: false,
      formStatus: 'pending',
      serverError: null,
      role: '',
      roles: [
        'Sales agent',
        'Distributor',
        'Post supervisor',
        'Producer',
        'Director',
        'Post facility',
        'Colorist',
        'Other'
      ]
    }
  },
  computed: {},
  created() {
    this.role = this.defaultRole
  },
  head() {
    return {
      title: 'Contact Filmflow'
    }
  },
  methods: {
    sendLead() {
      if (!this.firstName || !this.lastName || !this.email) {
        this.invalidForm = true
      } else {
        this.formStatus = 'sending'
        this.invalidForm = false
        this.serverError = null
        axios
          .post(`${process.env.apiPath}/send-lead`, {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            role: this.role,
            mailingList: this.mailingList
          })
          .then(response => {
            console.log(response)
            this.formStatus = 'sent'
          })
          .catch(error => {
            console.log(error)
            this.formStatus = 'pending'
            this.serverError = error
          })
      }
    }
  }
}
</script>
