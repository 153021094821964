<template>
  <div class="film-delivery">
    <div class="content-block">
      <section class="feature-detail first">
        <div class="info-box hero" style="margin-top: 0;">
          <h2 class="callout">Film delivery simplified.</h2>
          <p class="callout-sub">
            Filmflow is a new deliverables fulfillment service with transparent
            pricing, end-to-end status tracking, and fast turnaround.
          </p>
        </div>
        <div class="info-box center">
          <img
            src="~assets/img/hero.png"
            alt="Filmflow delivery progress screenshot"
            class="hero-image"
          >
        </div>
      </section>
    </div>

    <div class="content-block alt">
      <section class="feature-detail">
        <div class="info-box top three-up">
          <h3>For distributors and sales agents</h3>
          <img
            src="~assets/img/globe.svg"
            alt="Gear"
            style="width: 6rem; float: right; padding: 0 0 1rem 1rem"
          >
          <p>
            Stop wasting weeks slogging through piles of email as filmmakers attempt to coordinate delivery to you.
            We can create a typical set of digital deliverables for a feature in about 5 business days.
          </p>
          <div class="learn">
            <nuxt-link class="button green" to="dist">Learn about taking delivery <br> through Filmflow</nuxt-link>
          </div>
        </div>
        <div class="info-box top three-up">
          <h3>For post facilities and freelancers</h3>
          <img
            src="~assets/img/desk.svg"
            alt="Gear"
            style="width: 6.3rem; float: right; padding: 0 0 1.4rem 1.2rem"
          >
          <p>
            You'd rather focus on the creative aspects of post than than worry about this week's
            best practices for DCP subtitle creation or web video encoding, but
            you still need to offer your clients robust delivery options.
          </p>
          <div class="learn">
            <nuxt-link class="button green" to="post">Learn about partnering <br> with Filmflow</nuxt-link>
          </div>
        </div>
        <div class="info-box top three-up">
          <h3>For filmmakers and post supervisors</h3>
          <img
            src="~assets/img/slate.svg"
            alt="Slate"
            style="width: 5.5rem; float: right; padding: 0.1rem 0 1rem 1rem"
          >
          <p>
            Get paid sooner, streamline paperwork delivery, and have more visibility into the status of the delivery process.
            We've been delivering films for over a decade; let us deliver yours.

          </p>
          <div class="learn">
            <nuxt-link class="button green" to="filmmaker">Learn about delivering <br> through Filmflow</nuxt-link>
          </div>
        </div>
      </section>
    </div>

    <div class="content-block standout">
      <LeadCollect default-role="Sales agent"/>
    </div>
  </div>
</template>

<script>
import LeadCollect from '@/components/LeadCollect'

export default {
  components: { LeadCollect },
  head() {
    return {
      title: 'Filmflow: Film delivery simplified'
    }
  }
}
</script>
